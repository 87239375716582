import styled from 'styled-components';
import { spacing } from '../../theme';

export const IconWrapper = styled.div`
  position: relative;

  > span {
    position: absolute;
    top: -0.2rem;
    right: 0.1rem;
    width: ${spacing('xs')};
    height: ${spacing('xs')};
  }
`;
