import { ManfrediteDeleteRequest } from '@asgard/api-common';
import { useMutation, UseMutationOptions } from '@asgard/ui-common';
import { FetchError } from 'model';
import { ServiceManfredite } from 'services/service.manfredite';
import { USE_MANFREDITE_KEY } from './useManfredite.constants';
import { UseManfrediteDelete } from './useManfrediteDelete.types';

export const useManfrediteDelete: UseManfrediteDelete = () => {
  const { trigger: deleteManfredite } = useMutation<
    ManfrediteDeleteRequest,
    void,
    FetchError
  >(USE_MANFREDITE_KEY, ServiceManfredite.remove);

  return {
    deleteManfredite: async (
      req: ManfrediteDeleteRequest,
      opts?: UseMutationOptions<void, FetchError>
    ) => {
      await deleteManfredite(req, {
        ...opts,
        populateCache: () => undefined
      });
    }
  };
};
