import styled, { css } from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  radius,
  shadow,
  spacing,
  zindex
} from '../../theme';

export const StyledDropdown = styled.ul<{
  tabindex: string;
  isDropUp?: boolean;
  isAlignLeft?: boolean;
}>`
  background-color: ${color('white')};
  padding: ${spacing('xs')};
  border: 2px solid ${color('midnight_200')};
  border-radius: ${radius('md')};
  box-shadow: ${shadow('lg')};
  position: absolute;
  right: 0;
  top: calc(100% + ${spacing('xs')});
  z-index: ${zindex('dropDown')};
  max-height: 25rem;
  overflow-y: auto;

  ${({ isDropUp }) =>
    isDropUp &&
    css`
      top: unset;
      bottom: calc(100% + ${spacing('xs')});
    `}

  ${({ isAlignLeft }) =>
    isAlignLeft &&
    css`
      right: unset;
      left: 0;
    `}
`;

export const StyledFilter = styled.li`
  background-color: transparent;
  margin-bottom: ${spacing('xs2')};

  input {
    min-width: 220px;
    width: 100%;
  }
`;

export const StyledTitle = styled.li`
  color: ${color('text_subtle')};
  font-size: ${fontSize('xs')};
  line-height: 1.333;
  font-weight: ${fontWeight('extraBold')};
  text-transform: uppercase;
  padding-top: ${spacing('xs')};
  padding-left: ${spacing('mdlg')};
  padding-right: ${spacing('mdlg')};
`;

export const StyledOption = styled.li`
  background-color: transparent;
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('medium')};
  display: flex;
  align-items: center;
  gap: ${spacing('xs')};
  transition: 0.2s;

  &:not(:last-child) {
    margin-bottom: ${spacing('xs2')};
  }

  a {
    display: flex;
    color: inherit;
    transition: 0.2s;
    padding: ${spacing('mdlg')};
    min-width: 220px;
    gap: ${spacing('xs')};

    &:hover {
      color: inherit;
    }
  }

  button {
    display: flex;
    color: ${color('text_default')};
    transition: 0.2s;
    padding: ${spacing('mdlg')};
    min-width: 220px;
    gap: ${spacing('xs')};
    background-color: transparent;
    border: 0;
    text-align: left;
    font-family: inherit;

    &:hover {
      color: inherit;
    }
  }

  svg {
    flex-shrink: 0;
    width: ${spacing('md')} !important;
    height: ${spacing('md')};
    color: ${color('midnight_300')};
    transition: 0.2s;
    margin-right: ${spacing('xs2')};
  }

  &:hover {
    background-color: ${color('midnight_150')};
    color: ${color('text_default')};
    cursor: pointer;

    svg {
      color: ${color('midnight_400')};
    }
  }

  &:active {
    background-color: ${color('midnight_200')};
    color: ${color('midnight_400')};

    svg {
      color: currentColor;
    }
  }

  &.active {
    background-color: ${color('active')};
    color: ${color('text_over_active')};

    svg {
      color: currentColor;
    }
  }

  &.danger {
    color: ${color('negative_500')};
    svg {
      color: ${color('negative_500')};
    }
    span {
      color: ${color('negative_500')};
    }
  }

  &.has-separator-top {
    position: relative;
    margin-top: ${spacing('xs')};

    &::before {
      content: '';
      position: absolute;
      top: -${spacing('xs2')};
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${color('midnight_200')};
    }
  }
`;
