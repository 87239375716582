import {
  ManfrediteDeleteRequest,
  ManfrediteResponse,
  ManfrediteUpdateRequest
} from '@asgard/api-common';
import { Manfredite } from 'model';
import { getApiPathUrl } from 'utils/api';
import * as fetch from 'utils/fetch';
import { getAuthHeader } from 'utils/headers';
import { API_MANFREDITE_PATH } from './service.manfredite.constants';
import { normalizeToManfredite } from './service.manfredite.normalizer';

/**
 * Service to interact with the Manfredite API
 */
export const ServiceManfredite = {
  /**
   * Get the current logged in Manfredite
   *
   * @param token (optional) token to use for authentication. If not present,
   *              the API URL will change to use NextJS API as a middleware to
   *              get the current logged in user token
   *
   * @returns the authenticated Manfredite information
   */
  get: async (token?: string): Promise<Manfredite> => {
    const url = getApiPathUrl(API_MANFREDITE_PATH, token);

    const res = await fetch.get<ManfrediteResponse>(
      url,
      getAuthHeader(token),
      false
    );

    return normalizeToManfredite(res);
  },

  /**
   * Update the current logged in Manfredite. It will always use the NextJS
   * API as a middleware to get the current logged in user token
   *
   * @param manfrediteUpdateRequest The Manfredite new data
   *
   * @returns  the updated Manfredite information
   */
  update: async (
    manfrediteUpdateRequest: ManfrediteUpdateRequest
  ): Promise<Manfredite> => {
    const url = getApiPathUrl(API_MANFREDITE_PATH);
    const res = await fetch.put<ManfrediteUpdateRequest, ManfrediteResponse>(
      url,
      manfrediteUpdateRequest
    );

    return normalizeToManfredite(res);
  },

  requestChangeEmail: async (): Promise<void> => {
    const url = getApiPathUrl(`${API_MANFREDITE_PATH}/email-update/request`);

    return fetch.post<void, void>(url, undefined);
  },

  confirmChangeEmail: async (data: {
    email: string;
    hash: string;
  }): Promise<void> => {
    const url = getApiPathUrl(`${API_MANFREDITE_PATH}/email-update/operation`);

    return fetch.put<{ newEmail: string; requestHash: string }, void>(url, {
      newEmail: data.email,
      requestHash: data.hash
    });
  },

  /**
   * Finish the onboarding process for the current logged in Manfredite
   */
  finishOnboarding: async (): Promise<void> => {
    const url = getApiPathUrl(`${API_MANFREDITE_PATH}/onboarding`);

    return fetch._delete(url);
  },

  startLinkedinImport: async (): Promise<void> => {
    const url = getApiPathUrl(
      `${API_MANFREDITE_PATH}/integrations/linkedin-profile/import-job/strategy-scrapper`
    );

    return fetch.post(url, undefined);
  },

  startLinkedinImportWithApi: async (): Promise<string> => {
    const url = getApiPathUrl(
      `${API_MANFREDITE_PATH}/integrations/linkedin-profile/auth`
    );

    const res = await fetch.post<void, { url: string }>(url, undefined);

    return res.url;
  },

  /**
   * Remove the current logged in Manfredite. It will always use the NextJS
   * API as a middleware to get the current logged in user token
   *
   * @param manfrediteDeleteRequest The Manfredite delete reasons
   */
  remove: async (
    manfrediteDeleteRequest: ManfrediteDeleteRequest
  ): Promise<void> => {
    const url = getApiPathUrl(API_MANFREDITE_PATH);

    return fetch._deleteWithBody(url, manfrediteDeleteRequest);
  },

  /**
   * Get the MAC for the current logged Manfredite
   *
   * @returns the Manfredite MAC
   */
  downloadManfrediteAsMAC: async (): Promise<any> => {
    const url = getApiPathUrl(API_MANFREDITE_PATH);

    return fetch.get<any>(`${url}/export`);
  }
};
