import { useQuery } from '@asgard/ui-common';
import { useUser } from '@auth0/nextjs-auth0/client';
import { FetchError, Manfredite } from 'model';
import { ServiceManfredite } from 'services/service.manfredite';
import { USE_MANFREDITE_KEY } from './useManfredite.constants';
import { UseManfredite } from './useManfredite.types';

export const useManfredite: UseManfredite = () => {
  const { user } = useUser();

  const {
    data: manfredite,
    isLoading,
    error
  } = useQuery<Manfredite, FetchError>(
    USE_MANFREDITE_KEY,
    async () => {
      return ServiceManfredite.get();
    },
    {
      avoidFetch: !user
    }
  );

  return {
    manfredite,
    isLoading,
    error
  };
};
