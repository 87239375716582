import { ManfrediteUpdateRequest } from '@asgard/api-common';
import { ManfrediteToRequest } from './useManfrediteUpdate.types';

export const manfrediteToRequest: ManfrediteToRequest = (manfredite, data) => {
  const request: ManfrediteUpdateRequest = {
    firstName: manfredite.firstName,
    lastName: manfredite.lastName,
    telephone: manfredite.telephone,
    headline: manfredite.headline,
    introduction: manfredite.introduction,
    goodPractices: manfredite.goodPractices,
    desiredJobDescription: manfredite.desiredJobDescription,
    desiredSalaryDescription: manfredite.desiredSalaryDescription,

    softSkills: manfredite.softSkills.map(({ id }) => id),
    desiredRoles: manfredite.desiredRoles.map(({ id }) => id),
    likedTechs: manfredite.likedTechs.map(({ id }) => id),
    dislikedTechs: manfredite.dislikedTechs.map(({ id }) => id),

    desiredSalaryId: manfredite.desiredSalary?.id,
    cvFileId: manfredite.cvFile?.id,
    avatarId: manfredite.avatar?.id,

    workplacePreferences: {
      ...manfredite.workplacePreferences,
      desiredLocations: manfredite.workplacePreferences.desiredLocations.map(
        ({ placeId }) => placeId
      )
    },

    currentSalary: manfredite.currentSalary,
    profilePreferences: manfredite.profilePreferences,
    availabilityStatus: manfredite.availabilityStatus,
    whereDoYouLiveId: manfredite.whereDoYouLive?.placeId,
    facts: manfredite.facts,
    links: {
      github: manfredite.links?.github,
      linkedin: manfredite.links?.linkedin,
      stackoverflow: manfredite.links?.stackoverflow,
      twitter: manfredite.links?.twitter,
      others:
        manfredite.links?.others?.map(({ url, title }) => ({
          url,
          desc: title
        })) || []
    },
    yearsOfExperienceId: manfredite.yearsOfExperience?.id,
    preferredLanguage: manfredite.preferredLanguage,
    isNewsletterActive: manfredite.isNewsletterActive,
    isInterestedOnWorkAtManfred: manfredite.isInterestedOnWorkAtManfred,
    tags: [...(manfredite.testingTags?.map(({ id }) => id) || [])],

    contractTypes: manfredite.contractTypes || [],

    ...data
  };

  return cleanRequest(request);
};

function cleanRequest(
  request: ManfrediteUpdateRequest
): ManfrediteUpdateRequest {
  return {
    ...request,

    // We ensure that the profile slug is always lowercase
    profilePreferences: {
      ...request.profilePreferences,
      slug: request.profilePreferences.slug.toLowerCase()
    },

    // We ensure that empty strings values are removed
    firstName: request.firstName || undefined,
    lastName: request.lastName || undefined,
    telephone: request.telephone || undefined,
    headline: request.headline || undefined,
    introduction: request.introduction || undefined,
    goodPractices: request.goodPractices || undefined,
    desiredJobDescription: request.desiredJobDescription || undefined,
    desiredSalaryDescription: request.desiredSalaryDescription || undefined
  };
}
