import { ManfrediteUpdateRequest } from '@asgard/api-common';
import { useMutation } from '@asgard/ui-common';
import { FetchError, Manfredite } from 'model';
import { ServiceManfredite } from 'services/service.manfredite';
import { USE_MANFREDITE_KEY } from './useManfredite.constants';
import { manfrediteToRequest } from './useManfrediteUpdate.adapter';
import { UseManfrediteUpdate } from './useManfrediteUpdate.types';

export const useManfrediteUpdate: UseManfrediteUpdate = () => {
  const { trigger: updateManfredite } = useMutation<
    ManfrediteUpdateRequest,
    Manfredite,
    FetchError
  >(USE_MANFREDITE_KEY, ServiceManfredite.update);

  return {
    updateManfredite,

    updateManfrediteFromModel: (manfredite, data, opts) => {
      const request = manfrediteToRequest(manfredite, data);

      return updateManfredite(request, {
        populateCache: (updatedManfredite) => {
          return updatedManfredite;
        },
        ...opts
      });
    },

    updateManfrediteTestingTags: (manfredite, data, opts) => {
      const request = manfrediteToRequest(manfredite, {
        // * NOTE: This method is not necessary right now, but it will be when we handle more tag types
        //
        // We could use:
        // ```
        // updateManfreidteFromModel(manfredite, { tags: [1, 2, 3]})
        // ```
        //
        // Right now, we only handle these "testing tags". When we handle more tag types, we
        // should change these code to compose the `tags` request attribute with all the
        // other tags types (without the previois "testing tags"), appending the new "testing tags".
        tags: [...data]
      });

      return updateManfredite(request, {
        populateCache: (updatedManfredite) => {
          return updatedManfredite;
        },
        ...opts
      });
    }
  };
};
