import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CSS from 'csstype';
import Link from 'next/link';
import React, { ReactNode, useState } from 'react';
import {
  StyledDropdown,
  StyledFilter,
  StyledOption,
  StyledTitle
} from './Dropdown.styled';
import { IconWrapper } from './IconWrapper.styled';
import Indicator from './Indicator.styled';
import InputText from './InputText';

export type DropdownOption = {
  label: string | ReactNode;
  value: string;
  href?: string;
  blank?: boolean;
  action?: () => void;
  icon?: IconProp;
  activeClassCondition?: boolean;
  color?: string;
  hasIndicator?: boolean;
  hasDanger?: boolean;
  hidden?: boolean;
  hasSeparatorTop?: boolean;
  onClick?: () => void;
};

export type DropdownProps = {
  id?: string;
  style?: CSS.Properties;
  isDropUp?: boolean;
  isAlignLeft?: boolean;
  options: DropdownOption[];
  onOptionSelect?: (selectedValue: string) => void;
  hasFilter?: boolean;
  filterPlaceholder?: string;
  title?: string;
};

const Dropdown = ({
  id,
  options,
  style,
  onOptionSelect,
  isDropUp = false,
  isAlignLeft = false,
  hasFilter = false,
  filterPlaceholder,
  title
}: DropdownProps): JSX.Element => {
  const [filterOptions, setFilterOptions] = useState('');

  const updateFilter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterOptions(e.target.value.toLowerCase());
  };

  return (
    <StyledDropdown
      id={id}
      style={style}
      isDropUp={isDropUp}
      isAlignLeft={isAlignLeft}
      tabindex="-1"
      role="listbox"
    >
      {title && <StyledTitle>{title}</StyledTitle>}
      {hasFilter && (
        <StyledFilter>
          <InputText
            type="search"
            sizes="sm"
            variant="search"
            placeholder={filterPlaceholder || ''}
            onChange={updateFilter}
          />
        </StyledFilter>
      )}
      {options
        .filter((option) => !option.hidden)
        .map((option, i) => (
          <React.Fragment key={i}>
            {(!hasFilter ||
              (hasFilter && filterOptions === '') ||
              (hasFilter &&
                typeof option.label === 'string' &&
                option.label.toLowerCase().includes(filterOptions))) && (
              <StyledOption
                className={`${option.activeClassCondition ? 'active' : ''} ${
                  option.hasDanger ? 'danger' : ''
                } ${option.hasSeparatorTop ? 'has-separator-top' : ''}`}
                role="option"
                aria-selected={option.activeClassCondition}
              >
                {option.href && (
                  <Link href={option.href} passHref>
                    <a
                      target={option.blank ? '_blank' : '_self'}
                      onClick={(e) => {
                        if (onOptionSelect) {
                          e.preventDefault();
                          onOptionSelect(option.value);
                        }

                        option.onClick?.();
                      }}
                    >
                      {option.icon && <FontAwesomeIcon icon={option.icon} />}
                      <span>{option.label}</span>
                    </a>
                  </Link>
                )}
                {option.action && (
                  <button type="button" onClick={option.action}>
                    {option.icon && (
                      <IconWrapper>
                        <FontAwesomeIcon icon={option.icon} />

                        {option.hasIndicator && <Indicator variant="filled" />}
                      </IconWrapper>
                    )}
                    <span>{option.label}</span>
                  </button>
                )}
              </StyledOption>
            )}
          </React.Fragment>
        ))}
    </StyledDropdown>
  );
};

export default Dropdown;
