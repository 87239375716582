import styled, { css } from 'styled-components';
import { color, radius, spacing } from '../../theme';

const roleVariants: { [key: string]: { bgColor: (key: any) => any } } = {
  neutral: {
    bgColor: color('midnight_200')
  },
  active: {
    bgColor: color('positive_400')
  },
  positive: {
    bgColor: color('positive_500')
  },
  negative: {
    bgColor: color('negative_500')
  },
  filled: {
    bgColor: color('active')
  },
  notice: {
    bgColor: color('notice_500')
  }
};

const sizeVariants: { [key: string]: { size: (key: any) => any } } = {
  sm: {
    size: spacing('xs')
  }
};

export const Indicator = styled.span<{ variant?: string; sizes?: string }>`
  display: inline-flex;
  border-radius: ${radius('full')};
  width: ${spacing('md')};
  height: ${spacing('md')};

  ${({ variant }) =>
    variant &&
    roleVariants[variant] &&
    css`
      background-color: ${roleVariants[variant].bgColor};
    `}

  ${({ sizes }) =>
    sizes &&
    sizeVariants[sizes] &&
    css`
      width: ${sizeVariants[sizes].size};
      height: ${sizeVariants[sizes].size};
    `}
`;

export default Indicator;
