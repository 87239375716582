import { createContext, ReactNode, useState } from 'react';
import { PageProps } from './Page.types';

type PageDrawerContextValues = {
  isOpen: boolean;
  drawerContent: ReactNode;
  openDrawer: (children: ReactNode) => void;
  closeDrawer: () => void;
};

export const PageDrawerContext = createContext<PageDrawerContextValues>({
  isOpen: false,
  drawerContent: <></>,
  openDrawer: () => {
    // NOOP
  },
  closeDrawer: () => {
    // NOOP
  }
});

type PageDrawerContextProviderProps = Pick<PageProps, 'onDrawerClose'>;

export const FakePageDrawerContextProvider: React.FC<
  PageDrawerContextProviderProps
> = ({ children }) => {
  return <>{children}</>;
};

export const PageDrawerContextProvider: React.FC<
  PageDrawerContextProviderProps
> = ({ onDrawerClose, children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState<ReactNode>();

  function closeDrawer(): void {
    if (onDrawerClose) {
      onDrawerClose();
    }

    setDrawerOpen(false);
  }

  function openDrawer(children: ReactNode): void {
    setDrawerContent(children);

    setTimeout(() => {
      setDrawerOpen(true);
    }, 100);
  }

  return (
    <PageDrawerContext.Provider
      value={{
        isOpen: drawerOpen,
        drawerContent,
        openDrawer,
        closeDrawer
      }}
    >
      {children}
    </PageDrawerContext.Provider>
  );
};
